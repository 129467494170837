<template>
  <div class="branch-multiple-selecter">
    <p class="select-label sub-body-bold" v-html="title"></p>
    <v-select
      t-data="single-selected-branch"
      :class="{'branch-selector can-disable-selector': true, 'disable-selector': isDisabled}"
      :placeholder="placeholder"
      :items="branch"
      outlined
      required
      :rules="rules"
      color="primary500Color"
      dense
      v-model="selectedItem"
      item-text="codename"
      item-value="selected"
      :disabled="isDisabled"
      :hide-details="hideDetails"
      @change="change"
    >
    </v-select>
  </div>
</template>

<script>
export default {
  name: 'SingleSelectedBranch',
  data() {
    return {
      selectedItem: this.selectedBranch,
      rules: this.rule ? [this.rule] : [this.selectedRules],
    }
  },
  props: {
    selectedBranch: {type: [String, Object]},
    branch: {type: Array},
    title: {type: String},
    syncData: {type: Function},
    rule: {
      type: Function,
      required: false,
    },
    placeholder: {
      type: String,
      default: "Select Branch",
      required: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
      required: false,
    },
    isChanged: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  methods: {
    selectedRules() {
      return !!this.selectedBranch || 'Branch is required'
    },
    change(value) {
      return this.$emit('change', value)
    }
  },
  mounted() {
    if (this.selectedItem) {
     this.syncData([this.selectedItem])
    }
  },
  watch: {
    isChanged() {
      if (this.isChanged) this.selectedItem = this.selectedBranch
    },
    selectedBranch() {
      this.selectedItem = this.selectedBranch
    },
    selectedItem() {
      if (this.selectedItem) {
        this.syncData([this.selectedItem])
      }
    },
  }
}
</script>
<style scoped>
.select-label {
  color: var(--primary-text-color);
  margin-bottom: 0;
}

::v-deep .v-list-item__title, ::v-deep .v-input__slot {
  text-transform: uppercase;
}

</style>

<style>
.disable-selector > .v-input__control > .v-input__slot {
  background: var(----fail-chip-color);
}
</style>